import React, { Component } from "react"
import Header from "../components/basics-compoment/Header"
import Footer from "../components/basics-compoment/Footer"
import "../myStyle.css"
import HelmetCompoment from "../components/basics-compoment/HelmetCompoment"
import TextStyle from "../components/style-compoment/TextStyle"

// markup
class LegalNotice extends Component {


  // https://www.rocketlawyer.com/fr/fr/interview/c483cdb3-0419-4e1e-b401-c50e6cdc6274;page=1;document=7afdd284-5c53-412e-b551-d8c1794310e5
  render () {

    return (

      <React.Fragment>

        <HelmetCompoment></HelmetCompoment>

        <Header page="legalNotice"></Header>
        <div className="container">
          <TextStyle
            class="title big center"
            french="Mentions légales de Kodness"
            english="Mentions légales de Kodness"
            spanish="Mentions légales de Kodness"
            cssStyle={{padding: "80px 0px 40px 0px"}}
          ></TextStyle>
        </div>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="1. Propriété et direction"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Le nom de domaine https://xxxxxxxxxxxxxxxx est la propriété exclusive de la SAS unipersonnelle Kodness, dirigée par Monsieur Thomas Perrier et dont les informations légales sont disponibles ci-après :",
          <br></br>, <br></br>,
          "SAS unipersonnelle KODNESS",
          <br></br>, <br></br>,
          "32 Bd du Port, 95000 Cergy",
          <br></br>,<br></br>,
          "SIRET : XXXXXXXXXXXXXXx",
          <br></br>,<br></br>,
          "Numéro de TVA intracommunautaire : XXXXXXXXXXX",
          <br></br>,<br></br>,
          "Capital social de XXXX€",
          <br></br>,<br></br>,
          "Immatriculation au RCS de XXXXX : XXXXXXXXXX",
          <br></br>,<br></br>,
          "Directeur : Thomas Perrier",
          <br></br>,<br></br>,
          "Contact : info@trainfast.com"]}
        ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="2. Hébergeur"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content="Le site https://trainfastwebsitemain.gatsbyjs.io/ est hébergé par sur GATSBY CLOUD."
        ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="3. Droits d'auteur & Reproduction"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content="La reproduction de contenu de ce site, en tout ou partie, est interdite sans la permission écrite du Directeur de Publication et propriétaire du site de https://trainfastwebsitemain.gatsbyjs.io/ (sauf mention particulière). Le contenu de ce site inclut sa structure générale, le logo, les templates, les textes, les documents téléchargeables, les images animées ou non, les vidéos ainsi que leur design et les sons dont le site est composé. Toute utilisation ou extraction d’éléments du site non autorisée par l’éditeur est illicite et sanctionné pénalement par le délit de contrefaçon. Tout autre contenu n’appartenant pas à KODNESS (photos, textes, images, codes sources, logos, noms de produits ou marques citées, etc… ) est la propriété de leurs détenteurs respectifs. Seule la copie à usage privé est autorisée pour votre usage personnel, privé et non-commercial, sur votre ordinateur personnel. L’éditeur se réserve le droit de poursuivre tout acte de contrefaçon de ses droits de propriété intellectuelle."
        ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="4. Liens hypertextes"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content={["Les liens proposés vers d’autres sites sont communiqués à titre indicatif et ne sauraient engager la responsabilité de l’équipe du site https://trainfastwebsitemain.gatsbyjs.io/, tant en ce qui concerne les contenus que les conditions d’accès. A l’inverse, toute personne (physique ou morale) souhaitant créer un ou (des) lien(s) vers une ou plusieurs page(s) du présent site doit préalablement en informer le Directeur de la Publication. La mise en place de liens hypertextes est autorisée, sous réserve :",
          <br></br>,<br></br>,
          "– de ne pas utiliser la technique du lien profond, c’est-à-dire que les pages du site ne doivent pas être imbriquées à l’intérieur des pages d’un autre site, mais visibles par l’ouverture d’une fenêtre indépendante.",
          <br></br>,<br></br>,
         "– que la source qui pointera grâce à un lien hypertexte directement sur le contenu visé soit précisée.",
         <br></br>,<br></br>,       
          "– que les informations utilisées ne le soient qu’à des fins personnelles, éducatives, associatives ou professionnelles, toute utilisation à des fins commerciales ou publicitaires étant interdite.",
          <br></br>,<br></br>,
          "Cette autorisation ne s’applique en aucun cas aux sites internet diffusant des informations à caractère raciste, pornographique, xénophobe, polémique ou pouvant, d’une façon générale, porter atteinte à la sensibilité du plus grand nombre. Néanmoins, par principe sont autorisées, sans accord express préalable :",
          <br></br>,<br></br>,
          "– la citation, respectant le droit moral de l’auteur par l’indication de son nom et de la source. La citation est nécessairement courte, cette notion s’appréciant tant par rapport à la publication dont elle est extraite que par rapport à celle dans laquelle elle est introduite. La citation illustre un propos et ne doit pas concurrencer la publication à laquelle elle est empruntée. La multiplication des citations, aboutissant à une anthologie, est considérée comme une oeuvre dérivée, et donc soumise à l’accord préalable de l’auteur ou de l’ayant droit ;",
          <br></br>,<br></br>,
          "– la création d’un lien, à la condition impérative que ce lien ouvre une nouvelle fenêtre du navigateur et que la page atteinte par le lien ne soit pas imbriquée à l’intérieur d’autres pages, en particulier par voie de cadres, appartenant au site appelant que cette ou ces page(s) apparaisse(nt) dans une page entière sous l’URL du présent site."]}
        ></TextStyle>
        <TextStyle
          type="content"
          class="subtitle subtitle-container"
          content="5. Mise à jour des mentions légales"
        ></TextStyle>
        <TextStyle
          type="content"
          class="small content"
          content="Neo five fitness se réserve le droit de mettre à jour les présentes mentions légales à tout moment, en fonction de l’évolution du contenu du site et des contraintes supplémentaires de protection nécessaires. Neo five fitness invite donc tout utilisateur à visiter cette page lors de chaque consultation du site afin d’en prendre connaissance."
          cssStyle={{marginBottom: "80px"}}
        ></TextStyle>
        <Footer></Footer>
        </React.Fragment>

    )

  }

}

export default LegalNotice

